<template  lang="pug">
	v-container(class="section-pim")
		v-row(justify="center")
			//- choose opcion
			v-col( cols="12" lg="12" )
				
				v-tabs(
					background-color="blue-600"
						color="white"
					right)
					v-tab 
						label(class="d-none d-sm-flex") Priorizador 
						v-icon(class="hidden-sm-and-up") mdi-priority-high
					v-tab 
						label(class="d-none d-sm-flex") Excluir Familias 
						v-icon(class="hidden-sm-and-up") mdi-set-left-right
					v-tab
						label(class="d-none d-sm-flex") Carga de SKU
						v-icon(class="hidden-sm-and-up") mdi-file-upload
					v-tab-item
						v-card(class="card_segment p-0")
							v-card-title(class="pb-0")
								h1(align="center" class="title blue-600--text ") Productos priorizados
							v-card-text(class="pb-0")
								v-form(ref="form")
									v-container
										v-row
											v-col(cols="6"  md="2")
												v-radio-group(v-model="opcion")
													v-radio(label="Todos" value="todos" color="primary")
													v-radio(label="Limite" value="limite" color="primary")
											v-col(cols="6" md="2")
												v-text-field(
													label="Cantidad"
													v-model="limit"
													type="number"
													min="1"
													:disabled="opcion=='limite'?false:true"
													:rules="opcion=='limite'?requiredRules:[]"
													)
												v-btn(color="blue-500" class="white--text mr-3"  @click="getCsv" :disabled="loading") Descargar
											//- v-row(class="py-0")
											v-col(cols="12" md="4")
												v-list( subheader)
													v-list-item
														v-list-item-content
															//- v-list-item-title 
															v-progress-linear(
																:active="loading"
																:indeterminate="loading"
																absolute
																bottom
																color="blue-500"
																)
														v-list-item-action(v-if="showDownload")
															v-list-item-action-text
															v-btn(text @click="openUrl")
																v-icon(
																	color="yellow-600"

															) mdi-cloud-download
							
								
							transaccion-pim(v-bind:transaccionPrioritizer="transaccion_prioritizer")
					v-tab-item
						v-card(class="card_segment elevation-0")
							v-card-title(class="pb-0")
								h1(align="center" class="title blue-600--text ") Familias excluidas

							v-card-text(class="py-0 px-0 ")
								v-form(ref="formCategorias")
									v-container
										v-row(align="center" justify="center")
											v-col(cols="12" sm="4")
												v-select(v-model="categoria" label="Área" :items="categorias" :rules="requiredRules")
											v-col(cols="12" sm="4")
												v-file-input(
													v-model="files"
													accept=".xlsx" 
													label="Elegir archivo"
													:rules="rulesImg"
													
													)
												
											v-col(cols="12" sm="3" md="3")
												v-tooltip( top )
													template( v-slot:activator="{ on }")
														v-btn( icon v-on="on" x-small class="blue-600 mx-3" @click="help_family= !help_family")
															v-icon( color="white") mdi-help
													span Ver formato de subida

												v-btn(color="blue-500" class="white--text mr-3" :loading="loading" @click="loadCsv") cargar
											v-col(cols="12"  md="10")
												v-alert(type="error" v-if="show_error_familiy") {{error_family}}

												transition(name="slide-y-reverse-transition" mode="out-in")
													template(v-if="help_family")
														div
															p(class="title blue-500--text") Deberá contener las siguientes 6 columnas en el mismo orden:
															p(class="font-weight-black caption black--text ") | idu_departamentocodigo | idu_clasecodigo | idu_familiacodigo | des_departamento | des_clase | des_familia |
															v-spacer
															v-btn(text dark class="text-none green darken-1" target="_blank" href="https://drive.google.com/open?id=1J14_jhkLYydJLCogflI9vgTypbf4LsvCnbD4OehM834") Ir a  template 
																v-icon mdi-file-excel
												p( class=" blue-700--text text-end") *Se sobreescribirá los datos existentes del área cargada
								transaccion(ref="tab2")

					v-tab-item
						v-card(class="elevation-0")
							v-card-title(class="pb-0")
								h1(align="center" class="title blue-600--text ") Carga  de productos para campañas, altas y apuestas comerciales 
							v-card-text(class="py-0 px-0 ")
								v-form(ref="formSku")
									v-container
										v-row(align="center" justify="center")
											v-col(cols="12" sm="4" offset-sm="4")
												v-file-input(
													v-model="filesSKU"
													accept=".xlsx" 
													label="Elegir archivo"
													:rules="rulesImg"
													
													)
											v-col(cols="12" sm="3" md="3")
												v-tooltip( top )
													template( v-slot:activator="{ on }")
														v-btn( icon v-on="on" x-small class="blue-600 mx-3" @click="help_sku= !help_sku")
															v-icon( color="white") mdi-help
													span Ver formato de subida

												v-btn(color="blue-500" class="white--text mr-3" :loading="loading" @click="loadCsvSKU") cargar
											//- v-row(align="center" justify="end")
											v-col(cols="12" sm="10")
												v-alert(:type="show_sku.type" v-if="show_sku.status" )
													p {{show_sku.text}}
													p(v-for="item of items_deleted" class="mb-0") {{item}}
												transition(name="slide-y-reverse-transition" mode="out-in")
													template(v-if="help_sku")
														div
															p(class="title blue-500--text") Deberá contener las siguientes 10 columnas en el mismo orden:
															label Formato fecha:  AAAA-MM-DD
															p(class="font-weight-black caption black--text ") | sku | fecha | idu_departamentocodigo | idu_clasecodigo | idu_familiacodigo | idu_areacodigo | des_departamento | des_clase | des_familia | tipo |
															v-spacer
															label Tipo de campañas:
															p(class="font-weight-black caption black--text ") 
															ul
																li ALTA NUEVA
																li CAMPAÑA ECOMMERCE
																li APUESTA COMERCIAL
																li FOTO PROVEEDOR
																li EXCLUSIVO
																li CAMPAÑA 360
																li RETOMA DE FOTO
																li AGREGAR FOTO
																li MUESTRA
																li FOTO AMBIENTADA
																li FOTO CON MODELO

															v-spacer
															v-btn(text dark class="mt-5 text-none green darken-1" target="_blank" href="https://docs.google.com/spreadsheets/d/1gldFSTFrdOXi_Y64YwS00xkzSpAh5m0OdaLutzAYZBE/edit?usp=sharing") Ir a  template 
																v-icon mdi-file-excel

												p( class=" blue-700--text text-end") *Los sku existentes se sobreescribiran y el resto se agregaran a la lista.
								transaccion-sku(ref="tab3")


</template>
<script>

	import XLSX from 'xlsx'

	import TransaccionPIM from '../../components/TransaccionPIM'

	import Transaccion from '../../components/Transaccion'
	import TransaccionSKU from '../../components/TransaccionSKU'
	import PimService from '../../services/PimService'

	const pimService = new PimService();

	export default {
		name: 'DashboardPim',

		data() {
			return {
				loading:true,
				categorias: ["Ropa","Muebles"],
				categoria : '',

				help_family: false,
				help_sku: false,

				showDownload:false,
				url:'',
				limit:50,

				show_error_familiy: false,
				show_error_sku: false,
				items_deleted : [],
				
				error_family: '',
				// error_sku: '',
				show_sku: {
					status: false,
					type:'',
					text:'' 
				},

				recommender: 'recompra',
				opcion:'todos',

				// update files 

				files:[],
				filesSKU:[],

				transaccion_prioritizer:[],
				excel_items : [],

				requiredRules: [
					v => !!v || 'Este campo es necesario',
				],
				rulesImg: [
					v => !v.length || 'Este campo es necesario',
				],
			}
		},
		components:{
			'transaccion-pim': TransaccionPIM,
			'transaccion' : Transaccion,
			'transaccion-sku' : TransaccionSKU,
		},
		mounted(){
			this.fetchPIMPrioritizer()
		},
		methods:{
			getCsv: function(opcion){
				if(!this.$refs.form.validate())
					return;

				this.loading = true;
				this.showDownload = false;

				if(this.opcion=="todos"){
					
					this.excel_items = this.transaccion_prioritizer;
					this.loading = false;
					this.showDownload = true;

				}
				else
					this.fetchLimitPIMPrioritizer()
				
			},
			fetchPIMPrioritizer:function(){

				pimService.fetchPIMPrioritizer()
				.then((response)=>{
					
					this.transaccion_prioritizer = response.data.data
					this.loading = false;

				})
				.catch((error)=>{
					console.log("Error: No connect with management ")
				})
				.then((response)=>{
					this.loading = false;
				})

			},
			fetchLimitPIMPrioritizer:function(){

				pimService.fetchLimitPIMPrioritizer(this.limit)
				.then((response)=>{
					
					this.excel_items = response.data.data
					this.loading = false;
					this.showDownload = true;

				})
				.catch((error)=>{
					console.log("Error: No connect with recommender ")
				})
				.then((response)=>{
					this.loading = false;
				})

			},
			openUrl: function(){
				// window.open(this.url, "_blank");

				// export json to Worksheet of Excel
				// only array possible
				var animalWS = XLSX.utils.json_to_sheet(this.excel_items)

				// A workbook is the name given to an Excel file
				var wb = XLSX.utils.book_new() // make Workbook of Excel

				// add Worksheet to Workbook
				// Workbook contains one or more worksheets
				XLSX.utils.book_append_sheet(wb, animalWS, 'Items Priorizados') // sheetAName is name of Worksheet 

				// export Excel file
				XLSX.writeFile(wb, 'pim_items_priorizados.xlsx') // name of the file is 'book.xlsx'
			},
			loadCsv: function(){
				this.loading = true;
				this.show_error_familiy = false;
				if(!this.$refs.formCategorias.validate())
					return
				
				let formData = new FormData();

				// for(let file in this.files){
				// 	formData.append("files", this.files[file]);
				// }
				formData.append("files", this.files);
				formData.append("categoria", this.categoria);
				let config = {
					headers : {
						'Content-Type' : 'multipart/form-data',
					}
				}

				pimService.insertExcludeFamily(formData)
				.then((response)=>{
					this.categoria = -1;
					if(response.data.status=='success'){
						this.$refs.tab2.fetchTransaccionFamilies();
					}else{
						this.show_error_familiy = true;
						this.error_family = response.data.status;
					}
				})
				.catch((error)=>{
					console.log("Error: No connect with recommender ")
				})
				.then((response)=>{
					this.loading = false;
				})
			},
			loadCsvSKU: function(){
				this.loading = true;
				this.show_sku.status = false;

				if(!this.$refs.formSku.validate())
					return
				
				let formData = new FormData();

	
				formData.append("files", this.filesSKU);
				let config = {
					headers : {
						'Content-Type' : 'multipart/form-data',
					}
				}
				pimService.insertSKUCampaignPIM(formData)
				.then((response)=>{
					this.categoria = -1;
					// this.files = [];
					if(response.data.status=='success'){
						this.$refs.tab3.fetchTransaccionCampanas();
						
						this.show_sku.status = true;
						this.show_sku.type = 'success';

						if(response.data.msg!=''){
							this.show_sku.text = response.data.msg;
							this.items_deleted = response.data.items_deleted
						}else{
							this.show_sku.type = 'success';
							this.show_sku.text = 'Los registros fueron actualizados correctamente';
						}

					}else{
						
						this.show_sku.status = true;
						this.show_sku.type = 'error';
						this.show_sku.text =  response.data.status
					}
				})
				.catch((error)=>{
					console.log("Error: No connect with recommender ")
				})
				.then((response)=>{
					this.loading = false;
				})
			}
		},
		
	
	}
</script>
