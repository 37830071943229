<template lang="pug">
	v-dialog(
			v-model="dialog"
			@keydown.esc="close"
			max-width="600"
			persistent
			)
		v-card
			v-card-title
				p(class="headline") Agregar SKU
			v-card-text
				v-form(ref="form")
					v-container
						v-row
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.sku"
									label="SKU"
									prepend-icon="mdi-numeric"
									type="number"
									min="1"
									:rules="requireRules")
							v-col(cols="12" md="6")
								v-select(
									v-model="dataToSend.type"
									:items="options"
									label="Tipo"
									:rules="requireRules")
							v-col(cols="12" md="6")
								v-menu(
									v-model="menu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="290px")
									template( v-slot:activator="{ on }")
										v-text-field(
											v-model="dataToSend.fecha"
											label="Fecha"
											prepend-icon="mdi-calendar"
											readonly
											:rules="requireRules"
											v-on="on")
									v-date-picker( 
										v-model="dataToSend.fecha"
										locale="es"
										label="Fecha"
										@input="menu = false")

							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_departamentocodigo"
									:rules="requireRules"
									label="Departamento Codigo"
									type="number"
									min="1"
									max="9") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_clasecodigo"
									:rules="requireRules"
									label="Clase Codigo"
									type="number"
									min="1"
									max="99") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_familiacodigo"
									:rules="requireRules"
									label="Familia Codigo"
									type="number"
									min="1"
									max="999") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_areacodigo"
									:rules="requireRules"
									label="Area Codigo"
									type="number"
									min="1"
									max="9") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_departamento"
									:rules="requireRules"
									label="Departamento Descripción")
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_clase"
									:rules="requireRules"
									label="Clase Descripción")
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_familia" 
									:rules="requireRules"
									label="Familia Descripción")
			v-card-actions(class="pb-5")
				v-spacer
				v-btn(color="yellow-500" dark class="mr-3 font-weight-bold" @click="close") Cancelar
				v-btn(color="blue-500 font-weight-bold" dark  @click="save" :loading="loading") Guardar
	
</template>
<script>
	import PimService from '../services/PimService'
	const pimService = new PimService();

	export default {

		name: 'NewTransaccion',
		props:{
			dialog:{
				type: Boolean
			},
			dataToSend:{
				type: Object
			}
		},
		computed:{
		},
		data () {
			return {
				menu: false,
				loading: false,
				options : [
					'ALTA NUEVA',
					'CAMPAÑA ECOMMERCE',
					'APUESTA COMERCIAL',
					'FOTO PROVEEDOR',
					'EXCLUSIVO',
					'CAMPAÑA 360',
					'RETOMA DE FOTO',
					'AGREGAR FOTO',
					'MUESTRA',
					'FOTO AMBIENTADA',
					'FOTO CON MODELO'],
				
				// 'FOTO 360'
				requireRules: [
					v => !!v || 'Este campo es obligatorio',
				],
			}
		},
		mounted() {
		},
		methods:{

			// editItem (item) {
			// 	this.editedIndex = this.desserts.indexOf(item)
			// 	this.editedItem = Object.assign({}, item)
			// 	this.dialog = true
			// },


			save: function(){
				this.loading = true
				// if (this.editedIndex > -1) {
				// 	Object.assign(this.desserts[this.editedIndex], this.editedItem)
				// } else {
				// this.desserts.push(this.editedItem)
				// }
				if (!this.$refs.form.validate()) {
					console.log("no validate")
					this.loading = false;
					return;
				}

				let formData = new FormData();
				for ( var key in this.dataToSend ) {
					formData.append(key, this.dataToSend[key]);
				}

				pimService.storeSKU(formData)
				.then((response)=>{
					// this.transaccion = response.data.data
					this.$emit('closeDialog',false);
					this.loading = false;
				})
				.catch(errror=>{
					this.loading = false;
				})

			},
			close(){
				this.$emit('closeDialog',false);
			}

		}
	}
</script>