<template lang="pug">
	v-card
		v-dialog(
			v-model="deleteDialog"
			max-width="500"
		)
			
		v-card-title
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar productos"
				single-line
				class="ml-4"
				max-width="300")
			.flex-grow-1
			//- v-btn(  class="ml-5" color="blue-500" @click="dialog = true"  class="white--text") Agregar Campaña
		v-data-table(
			:headers="headers"
			:items="transaccion"
			:search="search"
			:items-per-page="10"
			class="elevation-0")
			
			template( v-slot:item.action="{ item }" align="center")
				v-btn( icon class="ma-2"   color="blue-500" @click="editItem(item)") 
					v-icon mdi-pencil
</template>
<script>

	import PimService from '../services/PimService';

	const pimService = new PimService();
	export default {
		name: 'Prioritizer',
		props:{
			transaccionPrioritizer:{
				type: Array
			},
		},
		data () {
			return {
				dialog: false,
				update: false,
				deleteDialog: false,

				search: '',
				headers: [
					{ 
						value: 'sku',
						text: 'SKU',
						align: "center",
						sortable: false
					},
					{ 
						value: 'area',
						text: 'Área',
						align: "center",
						sortable: false
					},
					{ 
						value: 'dept',
						text: 'Departamento',
						align: "center",
						sortable: false
					},
					{ 
						value: 'class',
						text: 'Clase',
						align: "center",
						sortable: false
					},
					{ 
						value: 'familia',
						text: 'Familia',
						align: "center",
						sortable: false
					},

					{ 
						value: 'des_area',
						text: 'Des Area',
						align: "center",
						sortable: false
					},
					{ 
						value: 'des_departamento',
						text: 'Des departamento',
						align: "center",
						sortable: false
					},
					{ 
						value: 'des_clase',
						text: 'Des clase',
						align: "center",
						sortable: false
					},
					{ 
						value: 'des_familia',
						text: 'Des familia',
						align: "center",
						sortable: false
					},
					{ 
						value: 'tipo',
						text: 'Tipo',
						align: "center",
						sortable: false
					},
					{ 
						value: 'ModelDSC',
						text: 'Modelo',
						align: "center",
						sortable: false
					},

					
					{ 
						value: 'CategoryDSC',
						text: 'Categoría',
						align: "center",
						sortable: false
					},
					{ 
						value: 'SizeNBR',
						text: 'Talla',
						align: "center",
						sortable: false
					},
					{ 
						value: 'SkinnySizeNBR',
						text: 'Talla skinny',
						align: "center",
						sortable: false
					},
					{ 
						value: 'CurvySizeNBR',
						text: 'Talla curvy',
						align: "center",
						sortable: false
					},
					{ 
						value: 'ExtraSizeFLG',
						text: 'Flag talla extendida',
						align: "center",
						sortable: false
					},
					{ 
						value: 'status',
						text: 'Estatus',
						align: "center",
						sortable: false
					},
					{
						value: 'StatusPimDSC',
						text: 'Estatus PIM',
						align: "center",
						sortable: false
					},


					// des_area, des_departamento,des_clase, des_familia

					// { text: 'Acciones', value: 'action', sortable: false },
				],
				transaccion : [],
			}
		},
		watch:{
			transaccionPrioritizer:function(val){
				this.transaccion = val
			}
		},
		components:{
		},
		mounted() {
		},
		methods:{
		}
	}
</script>