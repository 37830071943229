import BackendAuthService from "./BackendAuthService";

export default class PimService extends BackendAuthService {

	constructor() {
		super();
	}
	// =============================================== tab1

	fetchPIMPrioritizer() {
		return this.httpCall('pim/all', {
			method: 'GET'
		});
	}

	fetchLimitPIMPrioritizer(amount) {
		return this.httpCall('pim/limit/'+amount, {
			method: 'GET'
		});
	}

	// =============================================== ExcludeFamily
	getTransaccion() {
		return this.httpCall('pim/fetch-categories', {
			method: 'GET'
		});
	}

	

	insertExcludeFamily(data) {
		return this.httpCall('pim/update-category', {
			method: 'POST',
			data: data,
		});
	}

	// patch(id,data) {
	// 	return this.httpCall('pim/update-item/'+id, {
	// 		method: 'POST',
	// 		data: data
	// 	});
	// }
	
	store(data) {
		return this.httpCall('pim/save-family', {
			method: 'POST',
			data: data
		});
	}
	delete(id){
		return this.httpCall('pim/delete-family/'+id, {
			method: 'DELETE',
		});
	}

	// =============================================== Campaign

	getTransaccionSKU() {
		return this.httpCall('pim/fetch-sku', {
			method: 'GET'
		});
	}
	insertSKUCampaignPIM(data) {
		return this.httpCall('pim/update-sku', {
			method: 'POST',
			data: data,
		});
	}

	deleteSKU(id){
		return this.httpCall('pim/delete-sku/'+id, {
			method: 'DELETE',
		});
	}

	storeSKU(data) {
		return this.httpCall('pim/save-sku', {
			method: 'POST',
			data: data
		});
	}

	// =============================================== Imgs

	fetchImg(id){
		return this.httpCall('pim/fetch-imgs/'+id, {
			method: 'GET',
		});
	}

	storeImg(id,data){
		return this.httpCall('pim/upload-img/'+id, {
			method: 'POST',
			data
		});

	}

}
