<template lang="pug">
	v-card
		new-transaccion(
			v-on:closeDialog="closeDialog($event)"
			v-bind:dialog="dialog"
			v-bind:dataToSend="editedItem")
		v-dialog(
			v-model="deleteDialog"
			max-width="500"
		)
			
		v-card-title
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar familias"
				single-line
				class="ml-4"
				max-width="300")
			.flex-grow-1
			v-btn(  class="ml-5" color="blue-500" @click="dialog = true"  class="white--text") Agregar familia
		v-data-table(
			:headers="headers"
			:items="transaccion"
			:search="search"
			:items-per-page="10"
			class="elevation-0")
			
			template( v-slot:item.action="{ item }" align="center")
				v-btn( icon class="ma-2"   color="blue-500" @click="deleteItem(item)") 
					v-icon mdi-delete
</template>
<script>
	import NewTransaccion from './NewTransaccion';
	import PimService from '../services/PimService';

	const pimService = new PimService();
	export default {
		name: 'Transaccion',
		data () {
			return {
				dialog: false,
				update: false,
				deleteDialog: false,

				search: '',
				headers: [
					// { 
					// 	text: 'id', 
					// 	value: 'id', 
					// 	type: "number", 
					// 	sortable: true
					// },
					{ 
						text: 'departamentocodigo', 
						value: 'idu_departamentocodigo', 
						type: "number",
						align: "center",
						sortable: false
					},
					{ 
						text: 'clasecodigo', 
						value: 'idu_clasecodigo', 
						type: "number",
						align: "center",
						sortable: false },
					{ 
						text: 'familiacodigo', 
						value: 'idu_familiacodigo', 
						type: "number",
						align: "center",
						sortable: false 
					},
					{ 
						text: 'areacodigo', 
						value: 'idu_areacodigo', 
						type: "number",
						align: "center",
						sortable: false },
					{ 
						text: 'departamento', 
						value: 'des_departamento', 
						type: "text", 
						sortable: false},
					{ 
						text: 'clase', 
						value: 'des_clase', 
						type: "text", 
						sortable: true },
					{
						text: 'familia', 
						value: 'des_familia',
						type: "text", 
						sortable: true 
					},
					
					{ text: 'Acciones', value: 'action', sortable: false },
				],
				transaccion : [],

				editedIndex: -1,
				editedItem: {
					id :0,
					idu_departamentocodigo: 1,
					idu_clasecodigo: 1,
					idu_familiacodigo: 1,
					idu_areacodigo: 1,
					des_departamento:  '',
					des_clase: '',
					des_familia: '',
					
				},
				defaultItem: {
					id : 0,
					idu_departamentocodigo: 1,
					idu_clasecodigo: 1,
					idu_familiacodigo: 1,
					idu_areacodigo: 1,
					des_departamento:  '',
					des_clase: '',
					des_familia: '',
				},
			}
		},
		components:{
			'new-transaccion': NewTransaccion
		},
		mounted() {
			this.fetchTransaccionFamilies();
		},
		methods:{
			fetchTransaccionFamilies: function(){
				pimService.getTransaccion()
				.then((response)=>{
					this.transaccion = response.data.data
				})
			},
			deleteItem (item) {
				const index = this.transaccion.indexOf(item)
				if(confirm('¿Está seguro de eliminar este registro?')){
					pimService.delete(item.uid)
					.then(response=>{
						this.transaccion.splice(index, 1);
					})
				}
			},
			closeDialog(val){
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.dialog = val;
				this.fetchTransaccionFamilies();
			},

		}
	}
</script>