<template lang="pug">
	v-dialog(v-model="dialog" max-width="800px" min-height="500px" @keydown.esc="close" persistent)
		v-card(min-height="500px")
			v-container(class="imgVarieties")
				v-form(ref="form" )
					v-row
						v-col(cols="12" sm="10" )
							h1(class="title black--text font-weight-black") Subir imagenes de proveedor
					v-row( class="justify-center align-center")
						v-col(cols="12" sm="5")
							v-card(width="100px" height="100px" class="ma-auto elevation-0" )
								v-card-text(class="pa-0")
									input( type="file" v-on:change="imputImgs" class="inputFile"  accept="image/jpeg, image/png" multiple )
									v-icon(size="90" class="icon_float") mdi-camera

						v-col(cols="12" sm="1" class="text-center" )
							v-divider( vertical)
						v-col(cols="12" sm="6")
							div( v-for="(img,key) of imgsFile" :key="'imgUpload'+key")
								label {{img.name}} - {{img.size}} M
								v-progress-linear(
									class="mt-3"
									color="blue-500"
									:indeterminate="img.indeterminate"
									rounded
									:value="img.value"
									height="6")
								//- v-img(:src="img" contain)
					v-row
						v-col(cols="12" class="text-end" )
							v-btn(color="yellow-500" dark class="mr-3 font-weight-bold text-capitalize" @click="close") Cancelar
							v-btn(
								color="blue-500"
								dark
								class="mr-3 font-weight-bold text-capitalize"
								:loading="loading"
								v-if="imgsFile.length > 0"
								@click="store") Enviar Imgs
					v-row
						v-col(cols="12")
							v-divider
						v-col(cols="12" class="text-end")
							v-btn(
								icon 
								color="blue-500"
								:disabled="isFetchImgs"
								@click="fetchImgs")
								v-icon mdi-refresh
						
						v-col(cols="12" sm="6" v-for="(imgs,key)  of imgsUploaded" :key="'imgDown'+key")
							v-card
								v-img(:src="imgs" contain)
								v-card-actions(class="align-right")
									v-spacer
									v-btn(
										icon
										color="blue-500" download :href="imgs" target="_blank")
										v-icon mdi-cloud-download



			//- v-card-actions(class="pa-0")
				//- v-spacer
				

</template>
<script>
	import PimService from '../services/PimService';
	const pimService  =  new PimService();

	export default {
		name: 'UploadImgs',
		props: {
			dialogImg:{
				type: Boolean,
			},
			imgArray:{
				type: Array,
			},
			uid:{
				type: String,
			}
		},
		data(){
			return {
				loading: false,
				imgs_product: this.imgArray,

				dialog: this.dialogImg,

				imgsFile : [],
				imgsUploaded : [],


				editedIndex: -1,
				lengthOfRequest : 0,
				isFetchImgs: false,
			}
		},
		watch:{
			dialogImg: function(val){
				this.dialog = val;
				this.imgsUploaded = [];
				if(val)
					this.fetchImgs();
			}
		},
		computed: {
		},
		mounted () {
		},

		methods: {
			fetchImgs:function() {
				
				this.isFetchImgs = true

				pimService.fetchImg(this.uid)
				.then(response=>{
					this.imgsUploaded = response.data.imgs;
					this.isFetchImgs = false;

				})
				.catch(error=>{
					this.isFetchImgs = false;

				})

			},
			imputImgs(e){
				
				let files = e.target.files || e.dataTransfer.files;

				if (!files.length) return
				
				this.loading = true;
				this.imgsFile = [];

				files.forEach(file => {
					this.createImage(file)
					.then(urlImg=>{
						// console.log(urlImg)
						this.imgsFile.push(urlImg);
					})
					.catch(error=>{
						console.log('Error to create image')
					});
				});
				this.loading = false;
			},
			createImage(file) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.onload = () => {
						let size = file.size/1000000;
						resolve({
							
							name: file.name, 
							size: Math.round( size * 10 ) / 10,
							result: reader.result,
							indeterminate: false,
							value: 0
						}) 
					};
					reader.readAsDataURL(file);
				});
			},
			store: async function(type,size){
				this.loading = true;
				let lengthOfRequest = this.imgsFile.length;
				
				for(let img of  this.imgsFile){
					
					let index = this.imgsFile.indexOf(img);
					this.imgsFile[index].indeterminate = true;
					
					await this.uploadImg(img,index)
					.then((response)=>{
						console.log(this.editedIndex)

						this.imgsFile[response].value = 100;
						this.imgsFile[response].indeterminate = false;

						if(index == response){
							this.loading = false;
							this.fetchImgs();
						}

					})
					.catch(error=>{
						if(index == error){
							this.loading = false;
							this.imgsFile[error].indeterminate = false;
						}

					})
					
				}
			},
			uploadImg: function(img,index) {
				return  new Promise( (resolve, reject)=>{

					let formData = new FormData();
					formData.append('image', img.result);
					formData.append('category', 'normal');

					pimService.storeImg(this.uid,formData)
					.then(response =>{
						if(response.data.status=='success'){
							console.log()
							resolve(index);
						}else{
							reject(index);
							// resolve(index)
							// console.log(response.data)
						}
					})
					.catch(error=>{
						reject(index);

					})

				})
				

			},
			close(){
				this.imgsFile = []
				this.$emit('closeDialogImgs',false);
			},
			show_snackbar:function(){
				this.$emit('show_snackbar');
			}

		}
	}
</script>
<style lang="sass">
	.imgVarieties
		.v-card__text
			position: relative !important
			height: 100% !important
			.icon_float
				position: absolute
				opacity: 0.9
				top: 0
				bottom: 0
				left: 0
				right: 0
			.inputFile
				z-index: 10
				cursor: pointer
				width: 100%
				height: 100%
				position: absolute
				left: 0
				top: 0
				opacity: 0
</style>

