<template lang="pug">
	v-dialog(
			v-model="dialog"
			@keydown.esc="close"
			max-width="600"
			persistent
			)
		v-card
			v-card-title
				p(class="headline") Excluir Familia
			v-card-text
				v-form
					v-container
						v-row
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_departamentocodigo"
									label="Departamento Codigo"
									type="number"
									min="1"
									max="9") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_clasecodigo"
									label="Clase Codigo"
									type="number"
									min="1"
									max="99") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_familiacodigo" 
									label="Familia Codigo"
									type="number"
									min="1"
									max="999") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.idu_areacodigo" 
									label="Area Codigo"
									type="number"
									min="1"
									max="9") 
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_departamento" 
									label="Departamento Descripción")
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_clase" 
									label="Clase Descripción")
							v-col(cols="12" md="6")
								v-text-field(
									v-model="dataToSend.des_familia" 
									label="Familia Descripción")

			v-card-actions(class="pb-5")
				v-spacer
				v-btn(color="yellow-500" dark class="mr-3 font-weight-bold" @click="close") Cancelar
				v-btn(color="blue-500 font-weight-bold" dark  @click="save" :loading="loading") Guardar
	
</template>
<script>
	import PimService from '../services/PimService'
	const pimService = new PimService();

	export default {

		name: 'NewTransaccion',
		props:{
			dialog:{
				type: Boolean
			},
			dataToSend:{
				type: Object
			}
		},
		computed:{
		},
		data () {
			return {
				loading: false,
			}
		},
		mounted() {
		},
		methods:{

			// editItem (item) {
			// 	this.editedIndex = this.desserts.indexOf(item)
			// 	this.editedItem = Object.assign({}, item)
			// 	this.dialog = true
			// },


			save: function(){
				console.log(this.dataToSend)
				this.loading = true
				// return 
				// if (this.editedIndex > -1) {
				// 	Object.assign(this.desserts[this.editedIndex], this.editedItem)
				// } else {
				// this.desserts.push(this.editedItem)
				// }

				let formData = new FormData();
				for ( var key in this.dataToSend ) {
					formData.append(key, this.dataToSend[key]);
				}

				pimService.store(formData)
				.then((response)=>{
					// this.transaccion = response.data.data
					this.$emit('closeDialog',false);
					this.loading = false;
				})

				
			},
			close(){
				this.$emit('closeDialog',false);
			}

		}
	}
</script>