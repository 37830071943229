<template lang="pug">
	v-card
		new-transaccion(
			v-on:closeDialog="closeDialog($event)"
			v-bind:dialog="dialog"
			v-bind:dataToSend="editedItem")
		v-dialog(
			v-model="deleteDialog"
			max-width="500"
		)

		upload-images(
					v-on:closeDialogImgs="closeDialogImgs($event)"
					v-bind:dialogImg="dialogImg"
					v-bind:uid="uid")
		
		v-card-title
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar producto"
				single-line
				class="ml-4"
				max-width="300")
			.flex-grow-1
			v-btn(  class="ml-5" color="blue-500" @click="dialog = true"  class="white--text") Agregar SKU

		v-data-table(
			:headers="headers"
			:items="transaccion"
			:search="search"
			:items-per-page="10"
			class="elevation-0")
			
			template( v-slot:item.action="{ item }" align="center")
				v-btn( icon class=""  small color="blue-500" @click="deleteItem(item)") 
					v-icon mdi-delete
			
				v-btn( v-if="item.type=='FOTO PROVEEDOR'" icon class="" small  color="blue-500" @click="passDataToUploadImages(item)" ) 
					v-icon mdi-image-multiple
</template>
<script>
	import NewTransaccion from './NewTransaccionSKU';
	import UploadImages from './UploadImages';
	import PimService from '../services/PimService';

	const pimService = new PimService();
	export default {
		name: 'TransaccionSKU',
		data () {
			return {
				dialog: false,
				update: false,
				deleteDialog: false,

				dialogImg: false,

				search: '',
				headers: [
					{ 
						text: 'SKU', 
						value: 'sku', 
						type: "number",
						align: "center",
						sortable: false
					},
					{ 
						text: 'Fecha', 
						value: 'fecha', 
						type: "number",
						align: "center",
						sortable: false 
					},
					{ 
						text: 'idu_departamento', 
						value: 'idu_departamentocodigo', 
						type: "number",
						align: "center",
						sortable: false
					},
					{ 
						text: 'idu_clase', 
						value: 'idu_clasecodigo', 
						type: "number",
						align: "center",
						sortable: false },
					{ 
						text: 'idu_familia', 
						value: 'idu_familiacodigo', 
						type: "number",
						align: "center",
						sortable: false 
					},
					{ 
						text: 'idu_area', 
						value: 'idu_areacodigo', 
						type: "number",
						align: "center",
						sortable: false },
					{ 
						text: 'departamento', 
						value: 'des_departamento', 
						type: "text", 
						sortable: false
					},
					{ 
						text: 'clase', 
						value: 'des_clase', 
						type: "text", 
						sortable: false
					},
					{
						text: 'familia', 
						value: 'des_familia',
						type: "text", 
						sortable: true 
					},
					{ 
						text: 'Tipo', 
						value: 'type', 
						type: "number",
						align: "center",
						sortable: false
					},
					
					{ text: 'Operacciones', value: 'action', align: "center", sortable: false },
				],
				transaccion : [],
				imgArray : {},

				uid: '',

				editedIndex: -1,
				editedItem: {
					id :0,
					sku: 1,
					type:'',
					fecha: '',
					idu_departamentocodigo: 1,
					idu_clasecodigo: 1,
					idu_familiacodigo: 1,
					idu_areacodigo: 1,
					des_departamento:  '',
					des_clase: '',
					des_familia: '',
				},
				defaultItem: {
					id :0,
					sku: 1,
					type:'',
					fecha: '',
					idu_departamentocodigo: 1,
					idu_clasecodigo: 1,
					idu_familiacodigo: 1,
					idu_areacodigo: 1,
					des_departamento:  '',
					des_clase: '',
					des_familia: '',
				},
			}
		},
		components:{
			'new-transaccion': NewTransaccion,
			'upload-images': UploadImages
		},
		mounted() {
			this.fetchTransaccionCampanas();
		},
		methods:{
			fetchTransaccionCampanas: function(){
				pimService.getTransaccionSKU()
				.then((response)=>{
					this.transaccion = response.data.data
				})
			},
			deleteItem (item) {
				const index = this.transaccion.indexOf(item)
				if(confirm('¿Está seguro de eliminar este registro?')){
					pimService.deleteSKU(item.uid)
					.then(response=>{
						this.transaccion.splice(index, 1);
					})
				}
			},
			passDataToUploadImages: function(item){
				this.dialogImg = true;
				this.imgArray = item.ImagesJSON;
				this.uid = item.uid;
			},
			closeDialog(val){
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.dialog = val;
				this.fetchTransaccionCampanas();
			},

			closeDialogImgs(val){
				this.dialogImg = val;
			},


		}
	}
</script>